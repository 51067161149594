
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    fields: Number,
    error: {
      type: Boolean,
      default: false
    },
    devider: {
      type: Number
    }
  }
})
export default class VerificationCodeV2 extends Vue {
  fields: number
  error: boolean
  devider?: number

  code: string[] = []
  dataFromPaste: string[] | undefined

  created() {
    this.code = Array(this.fields)
  }

  handleInput(event: Event) {
    const inputType = (event as InputEvent).inputType
    let currentActiveElement = event.target as HTMLInputElement

    if (inputType === "insertText") {
      const next = currentActiveElement.nextElementSibling as HTMLElement
      ;(
        (next.nodeName === "INPUT"
          ? next
          : next.nextElementSibling) as HTMLElement
      )?.focus()
    }

    if (inputType === "insertFromPaste" && this.dataFromPaste) {
      for (const num of this.dataFromPaste) {
        if (currentActiveElement === null) break
        const next = currentActiveElement.nextElementSibling as HTMLElement
        let id: number = parseInt(currentActiveElement.id.split("_")[1])

        currentActiveElement.value = num
        this.code[id] = num

        if (currentActiveElement.nextElementSibling) {
          currentActiveElement = (
            next.nodeName === "INPUT" ? next : next.nextElementSibling
          ) as HTMLInputElement
          ;(
            (next.nodeName === "INPUT"
              ? next
              : next.nextElementSibling) as HTMLElement
          )?.focus()
        }
      }
    }
    this.onCodeChanged()
  }

  handleDelete(event: Event) {
    let value = (event.target as HTMLInputElement).value
    let currentActiveElement = event.target as HTMLInputElement
    const prev = currentActiveElement.previousElementSibling as HTMLElement
    if (!value)
      (
        (prev.nodeName === "INPUT"
          ? prev
          : prev.previousElementSibling) as HTMLInputElement
      )?.focus()
  }

  onPaste(event: Event) {
    this.dataFromPaste = (event as ClipboardEvent).clipboardData
      ?.getData("text")
      .trim()
      .split("")
      .filter((item) => item !== "-")
  }

  onCodeChanged() {
    const code = this.code.join("")
    this.$emit("code", code)
  }
}
