
import VerificationCodeV2 from "@/components/VerificationInputCode/verificationCodeV2.vue"
import { Options, Vue } from "vue-class-component"
import { getInviteByRefId } from "@/api/services/InviteService"
import { Invite } from "@/models/Invite"

@Options({
  components: {
    VerificationCodeV2
  }
})
export default class PassCode extends Vue {
  fields = 8
  value = ""
  error = false
  loading = false

  get validateLength() {
    return this.value.length === this.fields
  }

  onCodeChanged(value: string): void {
    this.value = value

    if (this.error) {
      this.error = false
    }
  }

  async onCreate() {
    this.loading = true

    const invite = await getInviteByRefId(this.value)

    this.loading = false
    if (invite instanceof Invite) {
      await this.$router.push({
        name: "welcomeV2",
        query: {
          id: invite.id,
          status: invite.projectStatus?.toLowerCase()
        }
      })
    } else {
      this.error = true
    }
  }
}
